<template>
  <default-layout>
    <post-layout>
      <div class="lecturer-information__container">
        <div>
          <img
            :src="lecturer[$i18n.locale][0].picture"
            alt=""
            class="lecturer-information__picture"
          />
        </div>
        <div class="lecturer-information__content">
          <h2>{{ lecturer[$i18n.locale][0].name }}</h2>
          <p>
            <b
              >{{ lecturer[$i18n.locale][0].position }} -
              {{ lecturer[$i18n.locale][0].academic_title }}</b
            >
          </p>
          <p>
            <b>{{ $t("lecturer.id") }}: </b> {{ lecturer[$i18n.locale][0].id }}
          </p>
          <p>
            <b>Email: </b>
            <a :href="'mailto:' + lecturer[$i18n.locale][0].email">{{
              lecturer[$i18n.locale][0].email
            }}</a>
          </p>
          <p v-html="lecturer[$i18n.locale][0].description"></p>
        </div>
      </div>
    </post-layout>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
import PostLayout from "@/layout/PostLayout.vue";

export default {
  data() {
    return {
      lecturer: { vi: [], en: [] },
    };
  },
  async created() {
    const res = await this.$axios.get(`/api/lecturer/${this.$route.params.id}`);
    this.lecturer = await Object.assign({}, res.data);
  },
  components: {
    DefaultLayout,
    PostLayout,
  },
};
</script>
<style lang="scss">
.lecturer-information {
  &__container {
    display: flex;
    margin: 0 auto;
  }
  &__picture {
    min-width: 300px;
    max-width: 300px;
  }
  &__content {
    margin-left: 150px;
    margin-top: 50px;
    h2 {
      margin: 0;
    }
    p {
      font-size: 14px;
    }
  }
}
</style>